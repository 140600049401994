.calendar-container {
    width:100%;
    height: 86vh;
    /* background-color: crimson; #f0ffff;
    border-radius: 50px;*/
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  h1 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 24px;
    font-family: 'Cursive', sans-serif;
  }

  .fc-toolbar-chunk{
    display: flex;
    width: 180px;
  }

  .fc-header-toolbar {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;   
    align-items: center;
    padding-left: 15px;
    padding-right: 25px;
  }

  .fc{
   height: 100%;
  }
 
  .fc .fc-toolbar-title {
    margin-top: 5px;
    font-size: 1.1rem;
    color: #ff69b4; 
  }
  
  .fc-daygrid-event, .fc-timegrid-event {
    font-size: 0.9em;
    padding: 2px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #efd1e1;
    color: #000;
    font-family: 'Cursive', sans-serif;
  }
  
  .fc-daygrid-event:hover, .fc-timegrid-event:hover {
    background-color: rgb(135, 208, 242); 
    color: #fff;
    background-color: #ff69b4;
  }
  
  .fc .fc-button {
    background: rgb(135, 208, 242); 
    border: none;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    font-family: 'Cursive', sans-serif;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .fc .fc-button:hover {
    background: #efd1e1; 
  }

  
  .fc .fc-daygrid-block-event .fc-event-time, 
  .fc .fc-daygrid-block-event .fc-event-title {
    padding: 0 1px;
    white-space: normal;
    font-family: 'Cursive', sans-serif;
  }
  
 

  
  .saisie-rdv-container h2 {
    margin-top: 0;
  }
  
  .saisie-rdv-container form {
    display: flex;
    flex-direction: column;
  }
  
  .saisie-rdv-container label {
    margin-bottom: 10px;
  }
  
  .saisie-rdv-container input {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }
  
  .saisie-rdv-container button {
    padding: 10px 20px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
  }
  