
/* Voici une partie du css*/

.container-navbuger{
    width: 95%;
    margin: auto;
    margin-top: 2px;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     background-color: plum;
     font-size: 1.1rem;
     font-weight: 800;
     border-radius: 10px;
     margin-bottom: 2px;
     height: 5%;
  }

.burger-navlink-active, .burger-icon {
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
    
  }

  .burger-navlink-active p{
    margin: 0;
    padding-right: 10px;    
  }
  .burger-icon{ 
    padding-right: 15px;
  }

  .burger-icon img {
    width: 15%;
    cursor: pointer;
  }

  .nav-active{
    color: crimson;
  }

 .nav-burg {
    color: black;
  }




  