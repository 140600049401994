.container-compte-phone{
    box-sizing: border-box;
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px auto;
}

.creation-compte-phone{
    margin: 2px auto;
}

.connexion-form-phone h2{    
    text-align: center;
    font-size: 1.8rem;
    color: #ff1493;
}

.creation-compte-phone p{
    font-size: 14px;
    margin-left: 190px;
    
}

.creation-compte-phone p:hover{
    cursor: pointer;
    font-style: italic;
    color: dimgrey;
}

.connexion-form-phone{
    padding: 25px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.connexion-form-phone label{
    margin-top: 20px;

}

.connexion-form-phone input{
    width: 250px;
    height: 40px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 25px;
}

.btn-mdp-oublier p{
    margin-top: 18px;
    font-size: 14px;
}

.btn-mdp-oublier p:hover{
    cursor: pointer;
    font-style: italic;
    color: dimgrey;
}


.btn-mdp-oublier button{
    width: 115px;
    height: 35px;
    border-radius: 8px;    
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.btn-mdp-oublier{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.bloc-connexion-client{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.bloc-message{
    width: 100%;
    margin: 15px;
    text-align: center;
}

/*****************************************************/
/*                                                   */
/*                   VerfifMail                      */
/*                                                   */
/*****************************************************/

.verif-mail-phone{
    margin: auto;
    padding: 25px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); 
}

.verif-mail-phone h1{
    text-align: center;
    font-size: 1.8rem;
    color: #ff1493;
}

.verfi-mail-form input{    
    width: 250px;
    height: 40px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 25px;
}

.information{
    width: 95%;
    text-align: center;
    color: rgb(243, 9, 56);
    font-weight: 500;
    font-size: 1rem;
    margin: 15px auto;
}

.verfi-code-form input{    
    width: 250px;
    height: 40px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 25px;
}

/*****************************************************/
/*                                                   */
/*               Saisi mot de passe                  */
/*                                                   */
/*****************************************************/

.container-saisie-mdp{    
    margin: auto;
    padding: 25px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); 
}


.container-saisie-mdp h2{
    text-align: center;
    font-size: 1.8rem;
    color: #ff1493;
}

.bloc-mdp input{    
    width: 250px;
    height: 40px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 25px;
}

.bloc-btn-saisie-mdp{    
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.bloc-btn-saisie-mdp button {
    width: 115px;
    height: 35px;
    border-radius: 8px;    
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

/*****************************************************/
/*                                                   */
/*              Creation Compte                      */
/*                                                   */
/*****************************************************/

.container-creation-compte{   
    margin: auto;
    padding: 15px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

}
.bloc-creation-client h1{
    text-align: center;
    font-size: 1.8rem;
    color: #ff1493;
}

.creation-compte-client-forme input {   
    width: 250px;
    height: 30px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 12px;
}

.btn-creation-client{ 
    margin-top: 5px;  
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.btn-creation-client button{
    width: 115px;
    height: 35px;
    border-radius: 8px;    
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}


/*****************************************************/
/*                                                   */
/*                  Mdp oublier                      */
/*                                                   */
/*****************************************************/
.container-mdp-oublier{   
    margin: auto;
    padding: 15px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.container-mdp-oublier h1{
    text-align: center;
    font-size: 1.8rem;
    color: #ff1493;
}

.mdp-oublier-form input {   
    width: 250px;
    height: 30px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding-left: 4px;
    margin-bottom: 16px;
}