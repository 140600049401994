
.container-priserdv-phone{
    width: 100%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.saisie-rdv-container-phone {
    padding: 10px;
    background-color: #f0ffff;
    border-radius: 10px;
    border: 1px solid #bfbcb6;    
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    width: 93;
    margin: 10px auto;

  }
  .saisie-rdv-container-phone h2{
    text-align: center;
    color:#ff69b4 ;
    margin: 5px auto;
    font-size: 1.3rem;
  }
  

.bloc-identité-rdv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
}
.bloc-identité-rdv strong{
    color: blueviolet;
}

.input-label-rdv input{ 
    margin-left: 1px; 
    height: 25px;
    width: 150px;
    border: 1px solid #bfbcb6;    
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}    

#select-presta  {   
    width: 150px;
    font-size: 0.9rem;
}

.bloc-prestation p{
 text-align: center;
 color: blueviolet;
}
.select-employe-form{ 
    margin-left: 5px; 
    height: 10px;  
    border: 1px solid #bfbcb6;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.label-presta-choix{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.label-presta-choix select{
    margin: 5px auto;
    /*padding-top: 5px;*/
    height: 60px;
    width: 250px;
    padding-left: 5px;
    border: 1px solid #bfbcb6;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.choix-totaux{
    text-align: center;
}
.btn-choix-presta{
    margin: 18px ;
    display: flex;
    justify-content: space-around;
}

.btn-choix-presta button{
    width: 105px;
    height: 30px;
    line-height: 0px;
    border-radius: 8px;
    border: 1px solid #bfbcb6;    
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.btn-form-rdv-phone {
    margin: 5px 5px;
    display: flex;
    justify-content: space-around;
}

.btn-form-rdv-phone button {
    border: 1px solid #bfbcb6; 
    border-radius: 8px; 
    width: 120px;
    height: 30px;
    line-height: 0;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    margin: 5px;
} 

.info-form-rdv-phone {
    color: red;
    font-weight: 500;
    text-align: center;
}

.label-heure-fin input{
    margin: 5px;
}

.label-select-employe select{
    height: 25px;
    width: 180px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    margin: 5px;
}

.label-select-employe strong{
    color: blueviolet;
}
