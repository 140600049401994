
.bloc-header-connexion img{
    width: 25%;
}
.bloc-header-connexion{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}