
    
       
@media (min-width: 701px) and (max-width: 800px) {

 /*   
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 15px 15px 15px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
    }*/

    .container-image{ 
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1px auto;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
        padding-top: 5px;
    }

    .bloc1-image{
       width: 25%;
       text-align: center;
    }

    .bloc1-texte{  
        box-sizing: border-box;
        width: 70%;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 0.8rem;
        text-align: justify;
    }

    .vernis{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
     }
 
     .bloc2-texte{
        width: 70%;
        height: auto;
         text-align: justify;
     }

     .outils{
        width: 25%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 2px;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1rem;
        height: 14%;
        margin: 1px 0px 1px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }
    
    }  
    


         
@media (min-width: 550px) and (max-width: 700px) {

   
    .container-image{ 
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1px auto;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
        padding-top: 5px;
    }

    .bloc1-image{
       width: 35%;
       text-align: center;
       margin: 10px auto;
    }

    .bloc1-texte{  
        box-sizing: border-box;
        width: 50%;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 0.8rem;
        text-align: justify;
    }

    .vernis{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
        margin: 10px auto;
     }
 
     .bloc2-texte{
        width: 50%;
        height: auto;
         text-align: justify;
     }

     .outils{
        width: 35%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 2px;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1rem;
        height: 14%;
        margin: 1px 0px 1px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }
    
    }  
    


             
@media (max-width: 551px) {

  
    .container-image{ 
        margin: 1px auto;
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1px auto;
    }

    .container-bloc2{ 
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 3px auto;
        padding-top: 5px;
    }

    .bloc1-image{
       width: 100%;
       text-align: center;
       margin: 10px auto;
    }

    .bloc1-texte{          
        box-sizing: border-box;
        width: 100%;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 0.7rem;
        text-align: justify;
    }

    .vernis{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 100%;
        text-align: center;
        margin: 10px auto;
     }
 
     .bloc2-texte{
        width: 100%;
        height: auto;
         text-align: justify;
     }

     .outils{
        width: 45%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
   
    .container-texte {
        width: 80%;
        box-sizing: border-box;
        margin: 2px auto;
        /*margin-bottom: 2px;*/
      }


      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left:0%;
        animation: scroll 22s linear infinite;
        font-size: 0.9rem;
        height: 14%;
        margin: 1px 0px;
        white-space: nowrap; 
        padding-right: 20%;
      }

   
     
      @keyframes scroll {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(-100%);
        }
      }
    
    }  
    