
   
@media  (min-width: 551px) and (max-width: 700px)  { 
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 5px 5px 5px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .container{
        display: flex;
        flex-direction: column; /* Les éléments seront disposés en colonne */
        height: 100vh; 
        justify-content: center;
        gap: 40px;
    }

    .container-img-logo{
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
    
    .img-alyce{
        width: 25%;
        height: auto;
    }    
    .container-service-bloc1{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 50px;
    }
    
    .container-service-bloc2{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }
    
        
    .bloc1-service{
        display: flex;
        gap: 100px;
        align-items: center;
    }
    .bloc2-service{
        display: flex;
        gap: 100px;
        align-items: center;
    }
    
    .bloc1-text1{
        width: 48%;
    }
    .bloc1-text1 p{
        font-size: 1.3rem; 
        line-height: 1.2;
        text-align: justify;

    }
    
    .bloc1-img-pose3{
        display: flex;
        justify-content: flex-end;
    }
    
    .bloc1-img-pose3 img{        
       max-width: 35%;
        height: auto;
    }        
    
    .bloc2-text2{
        width: 65%;
        display: flex;
        justify-content: flex-end;
    }
    .bloc2-text2 p{
        font-size: 1.3rem; 
        line-height: 1.2;
        text-align: justify;
        margin: 10px 0 0 150px ;
    }
    
    .bloc2-img-pose2{
        display: flex;
        justify-content: flex-start;
    }
    
    .bloc2-img-pose2 img{
        max-width: 38%;
        height: auto;
    }
    
    .bloc-img-pose-long {
        display: flex;
        justify-content: center;
        margin: 0px auto;
    }
    
    .bloc-img-pose-long img{
        max-height: 60%;
        width: auto;
    }
}


             
@media (max-width: 550px) {

    .service-container-phone {
        width: 100%;
    }

    .service-container-img-logo{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .service-container-img-logo img{
        width: 50%;
    }

    .container-service-bloc1{
        width: 100%;
    }

    .bloc1-service{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: blue;
    }

    .service-bloc1-img-pose3{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .service-bloc1-img-pose3 img {
        width: 53%;
    }

    .service-bloc1-text1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .service-bloc1-text1 p{
        width: 90%;
        font-size: 0.8rem;
        text-align: justify;
    }

    .service-bloc2-text2 { 
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .service-bloc2-text2 p{
        width: 90%;
        font-size: 0.8rem;
        text-align: justify;
    }


    .service-bloc2-img-pose2 { 
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center
    }

    .service-bloc2-img-pose2 img{
        width: 53%;
    }

    .service-bloc-img-pose-long{ 
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center
    }

    
    .service-bloc-img-pose-long img{
        width: 80%;
    }

    @media (max-height: 650px) {

        
    .service-bloc2-img-pose2 img{
        width: 43%;
    }

    .service-bloc1-img-pose3 img {
        width: 43%;
    }

    
    .service-container-img-logo img{
        width: 40%;
    }


    }  


}