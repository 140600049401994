


.bloc-contact{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-contact{
    margin:10px 60px;
    padding: 1px 30px;
    padding-bottom: 25px;
    width: 420px;
    border: 1px solid #bfbcb6;
    box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
    border-radius: 15px;
}

.card-contact h2{
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-contact p{
    margin-left: 25px;
}


.social-media{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.div-insta{ 
    width: 60px;
    height: 60px;
}

.div-facebook{
    width: 80px;
    height: 80px;
}

.img-facebook{
    width: 100%;
    height: auto;
}

.img-insta{
    width: 100%;
    height: auto;
}