
.app-container-desk {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}

.app-container-navbar{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}

.content-desk {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  margin: 7px auto;
} 

  .app-footer-desk {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px; 
    height: 35px; 
  } 

.container-composant-desk {
  box-sizing: border-box;
  margin: 8px ;
  height: 100%;
  width: 90%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #bfb6bc;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}


  
