
@media (min-width: 1701px)  { 
    .container-navbar{
        height: 5vh;
    }

}


.container-navbar{
    display: flex; /* Utilisation de Flexbox */
    flex-direction: column; /* Alignement en colonne */
    align-items: center;    
    width: 100%;
    margin-top: 1px;
    padding-top: 5px;     
    background-color: plum;
    border-radius: 10px;
    box-shadow: 8px 8px 5px rgba(255, 255, 255, 0.8);
  }

    
.navbar {
    width: 100%;
    justify-content: center; 
    color: #fff;
    font-size: 1.3rem;
    font-weight: 800; 
}

.navbar ul {
    display: flex; /* Les éléments de la liste sont en ligne */
    list-style: none; /* Supprimer les puces de la liste */
    margin: 0;
    padding: 0px;
    width: 100%; /* La liste prend toute la largeur */
    justify-content: space-around; /* Espacement égal entre les éléments */
}

.navbar li {
    flex: 1; /* Chaque élément occupe une partie égale de la largeur */
    text-align: center; /* Centrer le texte */
   /* margin-top: -5px;*/
}

.navbar a {
    color: white; 
}

.navbar a:hover {
    text-decoration: underline;
    color: #333;
}

.img-cadena-nav{
   /* margin-top: 5px;*/
    width: 10%;
}