

@media (min-width: 1501px)  { 

    .container-image{ 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-image img{
        width:15%;
        height: auto;

    }

    .container-contenu-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 2px auto;
        color: black;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 5px auto;
        color: black;
    }

    .bloc1-image{
       width: 40%;
       text-align: center;
    }

    .bloc1-texte{  
        width: 40%;
        text-align: justify;
        margin-right: 100px;
    }

    .paragraphe2{
        font-size: 1.2rem;
        text-align: justify;
    }

    .vernis{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
     }
 
     .bloc2-texte{
         width: 40%;
         text-align: justify;
         margin-left: 100px;
     }

     .outils{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1.6rem;
        height: 14%;
        margin: 5px 0px 5px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }

      .info2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .info2 h4{
        text-align: center;
        color: #ff69b4;
      }
      .info2 img {
        padding-left: 5px;
        width: 40px;
        height: auto;
      }
      
      .info2 img:hover {
        cursor: pointer;
      }

    
}  
    

@media (min-width: 1201px) and (max-width: 1500px) {

    /*
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 15px 15px 15px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
    }*/

    .container-image{ 
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
        color: black;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px auto;
        padding-top: 30px;
        color: black;
    }

    .bloc1-image{
       width: 30%;
       text-align: center;
    }

    .bloc1-texte{  
        box-sizing: border-box;
        width: 40%;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 1.2rem;
        text-align: justify;
    }

    .vernis{
        width: 60%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
     }
 
     .bloc2-texte{
         width: 30%;
         text-align: justify;
     }

     .outils{
        width: 45%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 2px;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1.6rem;
        height: 14%;
        margin: 10px 0px 10px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }

      .info2 h4{
        text-align: center;
        color: #ff69b4;
      }
    }  
    
    
@media (min-width: 801px) and (max-width: 1200px) {

    /*
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 15px 15px 15px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
    }
*/
    .container-image{ 
        width: 27%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px auto;
        padding-top: 15px;
    }

    .bloc1-image{
       width: 25%;
       text-align: center;
    }

    .bloc1-texte{  
        box-sizing: border-box;
        width: 380px;
        height: 120px;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 0.9rem;
        text-align: justify;
    }

    .vernis{
        width: 60%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
     }
 
     .bloc2-texte{
         width: 30%;
         text-align: justify;
     }

     .outils{
        width: 45%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 2px;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1.1rem;
        height: 14%;
        margin: 10px 0px 10px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }

      .info2 h4{
        text-align: center;
        color: #ff69b4;
      }
    
    }  
    
       
@media (min-width: 701px) and (max-width: 800px) {

    /*
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 15px 15px 15px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
    }*/

    .container-image{ 
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        flex-grow: 1;
    }

    .container-image img{
        width: 100%;
        height: auto;

    }
    
    .container-bloc1{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1px auto;
    }
    .container-bloc2{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px auto;
        padding-top: 5px;
    }

    .bloc1-image{
       width: 25%;
       text-align: center;
    }

    .bloc1-texte{  
        box-sizing: border-box;
        width: 70%;
        text-align: justify;
    }

    .paragraphe2{
        font-size: 0.8rem;
        text-align: justify;
    }

    .vernis{
        width: 40%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }

    .bloc2-image{
        width: 40%;
        text-align: center;
     }
 
     .bloc2-texte{
        width: 70%;
        height: auto;
         text-align: justify;
     }

     .outils{
        width: 25%;
        height: auto;
        border: 1px solid;
        border-radius: 50%;
    }
 
     .paragraphe3{
         font-size: 1rem;
     }

   
    .container-texte {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 2px;
      }
      
      .paragraphe1 {
        color:lightcoral;
        display: inline-block;
        padding-left: 100%;
        animation: scroll 18s linear infinite;
        font-size: 1rem;
        height: 14%;
        margin: 1px 0px 1px 0px;
      }

   
      
      @keyframes scroll {
        from {
          transform: translateX(0%);
        }
        to {
          transform: translateX(-100%);
        }
      }
    
    }  
    

