
.contenu-footer-phone{
    box-sizing: border-box;
    background-color: plum;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 700;
    width: 95%; 
    display: flex;
    justify-content: center; 
    align-items: center;
}
