/*
.container-composant {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #fff;
    box-shadow: 15px 15px 15px rgba(237, 35, 186, 0.2);
    padding: 20px;
    overflow-y: hidden;
}*/

.carousel .slide img {
    max-width: 100%;
    height: auto; /* Ajustement de la taille des images */
}

