@media (min-width: 1701px)  {  
/*
    .container-composant{
        box-sizing: border-box;
        margin: 15px auto;
        height: 100%;
        width: 90%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 5px 5px 5px rgba(237, 35, 186, 0.2);
        overflow-y: auto;
        overflow-x: hidden;
    }*/
    .container{
        display: flex;
        flex-direction: column; /* Les éléments seront disposés en colonne */
        height: 100vh; 
    }

    .container-img-logo{
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }
    
    .img-alyce{
       max-width: 20%;
        height: auto;
    }    
    .container-service-bloc1{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    
    .container-service-bloc2{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    
        
    .bloc1-service{
        display: flex;
        gap: 60px;
        align-items: center;
    }
    .bloc2-service{
        display: flex;
        gap: 60px;
        align-items: center;
    }
    
    .bloc1-text1{
        width: 50%;
        margin-right: 20px;
    }
    .bloc1-text1 p{
        font-size: 1.4rem; 
        line-height: 1.3;
        text-align: justify;

    }
    
    .bloc1-img-pose3{
        display: flex;
        justify-content: flex-end;
    }
    
    .bloc1-img-pose3 img{        
       max-width: 40%;
        height: auto;
    }        
    
    .bloc2-text2{
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
    }
    .bloc2-text2 p{
        width: 80%;
        font-size: 1.4rem; 
        line-height: 1.3;
        text-align: justify;
    }
    
    .bloc2-img-pose2{
        display: flex;
        justify-content: flex-start;
    }
    
    .bloc2-img-pose2 img{
        max-width: 80%;
        height: auto;
    }
    
    .bloc-img-pose-long {
        display: flex;
        justify-content: center;
        margin: 0px auto;
        flex-grow: 1;
    }
    
    .bloc-img-pose-long img{
        max-height: 80%;
        width: auto;
    }
}
 

@media (min-width: 1501px) and (max-width: 1700px)  { 
    .container{
        display: flex;
        flex-direction: column; /* Les éléments seront disposés en colonne */
        height: 100vh; 
        justify-content: center;
    }

    .container-img-logo{
        display: flex;
        justify-content: center;
        padding-top: 20px;
        margin: 10px auto;
    }
    
    .img-alyce{
        width: 45%;
        height: auto;
    }    
    .container-service-bloc1{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        padding-right: 50px;
    }
    
    .container-service-bloc2{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        margin: 20px auto;
    }
    
        
    .bloc1-service{
        display: flex;
        gap: 100px;
        align-items: center;
    }
    .bloc2-service{
        display: flex;
        gap: 100px;
        align-items: center;
    }
    
    .bloc1-text1{
        width: 48%;
    }
    .bloc1-text1 p{
        font-size: 1.3rem; 
        line-height: 1.2;
        text-align: justify;

    }
    
    .bloc1-img-pose3{
        display: flex;
        justify-content: flex-end;
    }
    
    .bloc1-img-pose3 img{        
       max-width: 30%;
        height: auto;
    }        
    
    .bloc2-text2{
        width: 65%;
        display: flex;
        justify-content: flex-end;
    }
    .bloc2-text2 p{
        font-size: 1.3rem; 
        line-height: 1.2;
        text-align: justify;
        margin: 10px 0 0 150px ;
    }
    
    .bloc2-img-pose2{
        display: flex;
        justify-content: flex-start;
    }
    
    .bloc2-img-pose2 img{
        max-width: 33%;
        height: auto;
    }
    
    .bloc-img-pose-long {
        display: flex;
        justify-content: center;
        margin: 0px auto;
        flex-grow: 1;
    }
    
    .bloc-img-pose-long img{
        max-height: 60%;
        width: auto;
    }
        
}
    
    
    @media (min-width: 1301px) and (max-width: 1500px)  { 
        .container-composant{
            box-sizing: border-box;
            margin: 15px auto;
            height: 100%;
            width: 90%;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 5px 5px 5px rgba(237, 35, 186, 0.2);
            overflow-y: auto;
            overflow-x: hidden;
        }
        .container{
            display: flex;
            flex-direction: column; /* Les éléments seront disposés en colonne */
            height: 100vh; 
            justify-content: center;
            gap: 29px;
        }
    
        .container-img-logo{
            display: flex;
            justify-content: center;
            padding-top: 20px;
        }
        
        .img-alyce{
            width: 25%;
            height: auto;
        }    
        .container-service-bloc1{
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 50px;
        }
        
        .container-service-bloc2{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0;
        }
        
            
        .bloc1-service{
            display: flex;
            gap: 100px;
            align-items: center;
        }
        .bloc2-service{
            display: flex;
            gap: 100px;
            align-items: center;
        }
        
        .bloc1-text1{
            width: 48%;
        }
        .bloc1-text1 p{
            font-size: 1.3rem; 
            line-height: 1.2;
            text-align: justify;
    
        }
        
        .bloc1-img-pose3{
            display: flex;
            justify-content: flex-end;
        }
        
        .bloc1-img-pose3 img{        
           max-width: 35%;
            height: auto;
        }        
        
        .bloc2-text2{
            width: 65%;
            display: flex;
            justify-content: flex-end;
        }
        .bloc2-text2 p{
            font-size: 1.3rem; 
            line-height: 1.2;
            text-align: justify;
            margin: 10px 0 0 150px ;
        }
        
        .bloc2-img-pose2{
            display: flex;
            justify-content: flex-start;
        }
        
        .bloc2-img-pose2 img{
            max-width: 38%;
            height: auto;
        }
        
        .bloc-img-pose-long {
            display: flex;
            justify-content: center;
            margin: 0px auto;
        }
        
        .bloc-img-pose-long img{
            max-height: 60%;
            width: auto;
        }
}


   
@media (min-width: 701px) and (max-width: 1300px)  { 
        .container-composant{
            box-sizing: border-box;
            margin: 15px auto;
            height: 100%;
            width: 90%;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 5px 5px 5px rgba(237, 35, 186, 0.2);
            overflow-y: auto;
            overflow-x: hidden;
        }
        .container{
            display: flex;
            flex-direction: column; /* Les éléments seront disposés en colonne */
            height: 100vh; 
            justify-content: center;
            gap: 40px;
        }
    
        .container-img-logo{
            display: flex;
            justify-content: center;
            padding-top: 20px;
        }
        
        .img-alyce{
            width: 25%;
            height: auto;
        }    
        .container-service-bloc1{
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 50px;
        }
        
        .container-service-bloc2{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0;
        }
        
            
        .bloc1-service{
            display: flex;
            gap: 100px;
            align-items: center;
        }
        .bloc2-service{
            display: flex;
            gap: 100px;
            align-items: center;
        }
        
        .bloc1-text1{
            width: 48%;
        }
        .bloc1-text1 p{
            font-size: 1.3rem; 
            line-height: 1.2;
            text-align: justify;
    
        }
        
        .bloc1-img-pose3{
            display: flex;
            justify-content: flex-end;
        }
        
        .bloc1-img-pose3 img{        
           max-width: 35%;
            height: auto;
        }        
        
        .bloc2-text2{
            width: 65%;
            display: flex;
            justify-content: flex-end;
        }
        .bloc2-text2 p{
            font-size: 1.3rem; 
            line-height: 1.2;
            text-align: justify;
            margin: 10px 0 0 150px ;
        }
        
        .bloc2-img-pose2{
            display: flex;
            justify-content: flex-start;
        }
        
        .bloc2-img-pose2 img{
            max-width: 38%;
            height: auto;
        }
        
        .bloc-img-pose-long {
            display: flex;
            justify-content: center;
            margin: 0px auto;
        }
        
        .bloc-img-pose-long img{
            max-height: 60%;
            width: auto;
        }
    }