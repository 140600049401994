
.contenu-footer-desk{
    box-sizing: border-box;
    background-color: plum;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 700;
    width: 90%; 
    display: flex;
    justify-content: center; 
    align-items: center;     
    height: 32px;   
}
