
.container-gallery-phone{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 100vh; 
    background-color: #fff;
    overflow-y: hidden;
}

.carousel .slide img {
    max-width: 100%;
    height: 100%; 
}