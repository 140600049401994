
.app-container-phone {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;  

  }
  
  .container-navbar {
      width: 90%;
      margin: 0px auto;
  }
    
  .content-phone  {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow-y: auto;
      height: 90%;
  }
   
  .app-footer-phone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 5%; 
  }


               
@media (max-width: 551px) {

  .container-composant-phone{
      box-sizing: border-box;
      height: 100%;
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #bfbcb6;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
      overflow-y: auto;
      overflow-x: hidden;
      margin: 10px auto;
  }
}
  


